import { PageProps } from "gatsby"
import React from "react"

import Seo from "../components/Seo"
import { MarginContainer } from "./agb"

const VersandPage: React.FC<PageProps> = () => {
  return (
    <MarginContainer>
      <Seo title="Versand & Lieferzeit" description="Versand und Lieferzeit" />

      <h3>Versandinformationen</h3>

      <p>Es gelten folgende Versandbedingungen:</p>
      <p>Die Lieferung erfolgt nur innerhalb Deutschlands.</p>

      <p>
        Versandkosten (inklusive gesetzliche Mehrwertsteuer) Lieferungen nach
        Mörfelden-Walldorf finden grundsätzlich ohne Versandkosten statt.
        Außerhalb von Mörfelden-Walldorf berechnen sich die Versandkosten anhand
        des Gewichts der jeweiligen Bestellung.
      </p>
      <p>Der Preis wird beim Checkout automatisch ermittelt und angezeigt.</p>

      <h3>Lieferfristen</h3>
      <p>
        Soweit in der Artikelbeschreibung keine andere Frist angegeben ist,
        erfolgt die Lieferung der Ware in Deutschland innerhalb von 4 - 5 Tagen
        nach Auftragsbestätigung (bei vereinbarter Vorauszahlung nach dem
        Zeitpunkt Ihrer Zahlungsanweisung). Beachten Sie, dass an Sonn- und
        Feiertagen keine Zustellung erfolgt.
      </p>

      <p>
        Haben Sie Artikel mit unterschiedlichen Lieferzeiten bestellt, versenden
        wir die Ware in einer gemeinsamen Sendung, sofern wir keine abweichenden
        Vereinbarungen mit Ihnen getroffen haben. Die Lieferzeit bestimmt sich
        in diesem Fall nach dem Artikel mit der längsten Lieferzeit den Sie
        bestellt haben.
      </p>
      <p>
        Bei individuell nach Kundenwunsch gestalteten Waren erfolgt die
        Erstellung der Korrekturvorlage innerhalb von 4-5 Tagen nach
        Auftragsbestätigung, die Ausführung der Gestaltungsarbeiten sowie die
        Lieferung der Ware innerhalb von 7-10 Tagen nach Freigabe der
        Korrekturvorlage (bei vereinbarter Vorauszahlung nach dem Zeitpunkt
        Ihrer Zahlungsanweisung), soweit in der Artikelbeschreibung insgesamt
        nichts anderes angegeben ist.
      </p>

      <p>
        Bei Selbstabholung informieren wir Sie per E-Mail über die
        Bereitstellung der Ware und die Abholmöglichkeiten. In diesem Fall
        werden keine Versandkosten berechnet. Bei Fragen finden Sie unsere
        Kontaktdaten im Impressum.
      </p>
    </MarginContainer>
  )
}

export default VersandPage
